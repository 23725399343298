<template>
  <div class="master-toko">
    <header-page>
      <span class="size14 fw-bold-700" style="color: #0b5fff; margin-right: 5px">Master</span>
      <span class="text-dark text-darken-5" style="margin-right: 5px">></span>
      <span class="text-dark fw-bold-400 size14">Sales</span>
    </header-page>

    <TableSales :result="result" :delete-data="deleteData" :get-data="getData" :edit-item="editItem"
      @filterBank="getFilter" :isLoading="isLoading" />

    <ModalSales :form-data="formPayload" :create-item="createItem" :search-country="searchCountry"
      :searchQuery="searchQuery" :edit-id="editId" :edit-item="editItem" :update-item="updateItem"
      :clean-up-form="cleanUpForm" :country-list="countryList" :messages="messages" :isLoading="isLoading"
      @getPayload="getPayload" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { VBToggle } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import HeaderPage from "@/components/HeaderPage.vue";
import ModalSales from "@/components/Master/Sales/Modal.vue";
import TableSales from "@/components/Master/Sales/Table.vue";

export default {
  title() {
    return "Master Sales";
  },
  components: {
    HeaderPage,
    TableSales,
    ModalSales,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      iconBank: null,
      formPayload: {
        branch_uuid: "",
        name: "",
        phone_number: "",
        phone_number_country: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      },
      filter: {
        search: "",
        per_page: 10,
        sort_type: "asc",
      },
      messages: {},
      userData: JSON.parse(localStorage.getItem("userData")),
      countryList: [],
      searchQuery: "",
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getPayload(value) {
      this.formPayload = value;
    },
    getFilter(value) {
      this.filter = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.iconBank = null;
      this.formPayload = {
        name: "",
        phone_number: "",
        phone_number_country: "",
        selectedCountry: {
          country_code: "ID",
          country_flag: "https://flagsapi.com/ID/shiny/64.png",
          country_name: "Indonesia",
          dial_code_preview: "+62",
          dial_code_value: "62",
        },
      };
      this.validations = "";
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.$store.commit(
        "sales/setFormPayload",
        (this.formPayload = {
          name: item.name,
          phone_number: item.phone_number,
          selectedCountry: item.phone_number_country,
        })
      );
      this.$bvModal.show("modal-sales");
    },
    async getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$store
        .dispatch("sales/getData", { uuid: "", params: queryParams })
        .then((result) => {
          this.isLoading = false;
          this.result = result.data;
          this.currentPage = result.data.current_page;
          this.getCountry();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    searchCountry: _.debounce(function (e) {
      const search = e.target.value;
      this.searchQuery = search;
      this.getCountry(search);
    }, 300),
    getCountry(search) {
      const isDialCode = /^\d+$/.test(search);
      const params = isDialCode ? { search: search } : { search: search };

      this.$http
        .get("/country-phone-codes?per_page=500", { params })
        .then((response) => {
          const uniqueCountries = response.data.data.data.filter((country, index, self) =>
              index === self.findIndex((c) => c.country_code === country.country_code)
          );
          this.countryList = uniqueCountries;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin membuat Sales baru?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formPayload.branch_uuid = this.userData.branch?.uuid;
          const form = this.preparePayload();
          this.isLoading = true;
          this.$store
            .dispatch("sales/postData", {
              query: "",
              payload: form,
            })
            .then(() => {
              this.getData();
              successNotification(this, "Success", "Sales berhasil di buat");
              this.cleanUpForm();
              this.$bvModal.hide("modal-sales");
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.validations) {
                this.validations = error.response.data.meta.validations;
              }
              this.isLoading = false;
            });
        }
      });
    },
    updateItem() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin mengubah data Sales ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formPayload.branch_uuid = this.userData.branch?.uuid;
          const form = this.preparePayload();
          this.isLoading = true;
          form.append("_method", "PATCH");

          this.$store
            .dispatch("sales/postData", {
              query: `/${this.editId}`,
              payload: form,
            })
            .then(() => {
              this.$bvModal.hide("form-bank-modal");
              this.getData();
              successNotification(this, "Success", "Sales berhasil diubah!");
              this.cleanUpForm();
              this.isLoading = false;
              this.$bvModal.hide("modal-sales");
            })
            .catch((error) => {
              if (error.response.data.meta.validations) {
                this.validations = error.response.data.meta.validations;
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
              this.isLoading = false;
            });
        }
      });
    },
    async deleteData(uuid) {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus Sales ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("sales/deleteData", `/${uuid}`)
            .then(() => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Sales berhasil dihapus",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
    preparePayload() {
      this.formPayload.selectedCountry != null
        ? (this.formPayload.phone_number_country =
          this.formPayload.selectedCountry.country_code)
        : "";
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key]) {
          if (key != "selectedCountry") {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      return form;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.master-toko {
  height: 100vh;
}
</style>
