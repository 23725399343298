var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"custom__modal--large",attrs:{"id":"modal-sales","hide-header":"","hide-footer":"","ok-only":"","ok-title":"Accept","centered":"","size":"lg","title":"Large Modal"}},[_c('div',{staticClass:"section"},[_c('header',{staticClass:"sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"3"}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":_vm.directPage}},[_c('feather-icon',{staticClass:"text-dark font-weight-bolder",attrs:{"size":"24","icon":"ArrowLeftIcon"}}),_c('span',{staticClass:"text-dark text-darken-4 fw-bold-600 size14 ml-1"},[_vm._v("Kembali")])],1)]),_c('b-col',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"cols":"6"}},[_c('h1',{staticClass:"text-dark h6 font-weight-bolder mb-0 text-center"},[_vm._v(" Informasi Sales ")])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"3"}},[_c('b-button',{staticClass:"px-3",attrs:{"disabled":!_vm.formPayload.name},on:{"click":function($event){_vm.editUuid == null ? _vm.createItem() : _vm.updateItem()}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Simpan ")],1)],1)],1)],1),_c('b-container',{staticClass:"mt-5 pt-5"},[_c('b-row',{staticClass:"justify-content-center h-100 bg-white pb-5"},[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"12"}},[_c('label',{attrs:{"for":"v-nama"}},[_vm._v("Nama "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',[_c('b-form-input',{staticClass:"custom__input",attrs:{"id":"v-nama","type":"text","placeholder":"Nama sales"},model:{value:(_vm.formPayload.name),callback:function ($$v) {_vm.$set(_vm.formPayload, "name", $$v)},expression:"formPayload.name"}}),(
                  _vm.messagesChild &&
                  _vm.messagesChild.name &&
                  Array.isArray(_vm.messagesChild.name) &&
                  _vm.messagesChild.name.length > 0
                )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messagesChild.name.join(", ")))]):_vm._e()],1)],1),_c('b-col',{staticClass:"mb-2 custom__form--input",attrs:{"cols":"12","md":"12"}},[_c('label',{attrs:{"for":"v-phone"}},[_vm._v("No. Telpon "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"label":_vm.currentLabel,"options":_vm.countryListData,"clearable":false},nativeOn:{"keyup":function($event){$event.stopPropagation();return _vm.searchCountry($event)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                var country_flag = ref.country_flag;
                var dial_code_preview = ref.dial_code_preview;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"6px"}},[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":country_flag,"alt":dial_code_preview}}),_c('p',{staticClass:"size12 mb-0"},[_vm._v("("+_vm._s(dial_code_preview)+")")])])]}},{key:"option",fn:function(ref){
                var country_flag = ref.country_flag;
                var dial_code_preview = ref.dial_code_preview;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"8px"}},[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":country_flag,"alt":dial_code_preview}}),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(dial_code_preview))])])]}}]),model:{value:(_vm.formPayload.selectedCountry),callback:function ($$v) {_vm.$set(_vm.formPayload, "selectedCountry", $$v)},expression:"formPayload.selectedCountry"}})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',[_c('b-form-input',{staticClass:"custom__input",attrs:{"id":"v-phone","type":"tel","placeholder":"Telpon Cabang"},model:{value:(_vm.formattedPhone),callback:function ($$v) {_vm.formattedPhone=$$v},expression:"formattedPhone"}}),(
                      _vm.messagesChild &&
                      _vm.messagesChild.phone_number &&
                      Array.isArray(_vm.messagesChild.phone_number) &&
                      _vm.messagesChild.phone_number.length > 0
                    )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.messagesChild.phone_number.join(", ")))]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }